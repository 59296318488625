@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --font-syne: "Syne";
  --font-plus: "Plus Jakarta Sans";
  --font-light: 300;
  --font-normal: 400;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;
  --text-primary: #debf51;
  --text-white: #ffffff;
  --text-secondary: #b0b0b0;
  --text-grey: #bfc0d2;
  --text-grey-2: #515e76;
  --switches-bg-color: #231f1b;
  --switches-label-color: linear-gradient(136deg, #debf51 0%, #bd791b 101.3%);
  --switch-bg-color: linear-gradient(136deg, #debf51 0%, #bd791b 101.3%);
  --switch-text-color: #fff;
  --light-mode-text: #1f2a37;
  --lm-text-grey: #4b5563;
  --lm-text-grey-2: #9ca3af;
  --lm-text-grey-3: #4b5563;
  --lm-text-black-2: #1f2a37;
  --lm-text-grey-4: #8e90af;
  --lm-switch-bg-color: linear-gradient(
    136deg,
    rgba(222, 191, 81, 0.05) 0%,
    rgba(189, 121, 27, 0.05) 101.3%
  );
  --lm-label: #374151;
  --lm-placeholder: #a1a1a1;
  --lm-table-even: #f9f9f9;
  --light-mode-active: linear-gradient(136deg, #debf51 0%, #bd791b 101.3%);
  --close-btn-bg: linear-gradient(
    136deg,
    rgba(222, 191, 81, 0.05) 0%,
    rgba(189, 121, 27, 0.05) 101.3%
  );
  --text-orange: #925905;
}
::selection {
  background: #debf51;
  color: #130c04;
}
@font-face {
  font-family: "ProximaNovaFont";
  src: url("./assets/fonts/ProximaNovaFont.eot");
  src: url("./assets/fonts/ProximaNovaFont.eot") format("embedded-opentype"),
    url("./assets/fonts/ProximaNovaFont.woff2") format("woff2"),
    url("./assets/fonts/ProximaNovaFont.woff") format("woff"),
    url("./assets/fonts/ProximaNovaFont.ttf") format("truetype"),
    url("./assets/fonts/ProximaNovaFont.svg#ProximaNovaFont") format("svg");
}
html {
  scroll-behavior: smooth;
}
body {
  padding: 0;
  margin: 0;
  font-family: var(--font-syne), sans-serif !important;
  /* background: url(assets/images/bg.png) #130c04 !important; */
  background: url(assets/images/grey-bg.png) #f2f2f7 !important;
  position: relative;
  overflow-x: hidden;
}
ul.navbar-nav.mx-auto.temp-navbar {
  visibility: hidden;
}

a {
  text-decoration: none !important;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul {
  padding: 0 !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}
.app-btn {
  padding: 8px 14px;
  border-radius: 8px;
  font-size: 17.45px;
  font-family: var(--font-plus), sans-serif;
  font-weight: var(--font-semibold);
  color: var(--text-white);
  background: linear-gradient(136deg, #debf51 0%, #bd791b 101.3%);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.apps-btn,
.read-btn {
  font-family: var(--font-plus), sans-serif;
  font-size: 20px;
  font-weight: var(--font-medium);
  color: rgba(255, 255, 255, 0.8);
  padding: 15px 40px;
  border-radius: 38px;
  background: linear-gradient(136deg, #debf51 0%, #bd791b 101.3%);
  backdrop-filter: blur(15px);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  white-space: nowrap;
}
.wallet-btn button {
  font-family: var(--font-plus), sans-serif;
  color: #1F2A37;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  padding: 10px 20px;
  border-radius: 50px;
  border: 1px solid #F5D255;
  background: rgba(245, 210, 85, 0.32);
  transition: 0.5s ease-in-out;
  &:hover {
    background: #F5D255;
  }
}

.navbar-brand img {
  width: 100%;
  height: auto;
}
.navbar-brand {
  padding: 0 !important;
}
.app-btn:hover,
.app-btn:focus,
.apps-btn:hover,
.read-btn:hover,
.apps-btn:focus,
.read-btn:focus {
  background: #debf51;
  transition: 0.5s ease-in-out;
}
.common-heading {
  & h3 {
    font-size: 51px;
    line-height: normal;
    font-weight: var(--font-bold);
    color: var(--text-white);
    padding: 0 0 10px;
    margin: 0;
  }
  & p {
    font-family: var(--font-plus), sans-serif;
    color: var(--text-secondary);
    font-size: 24px;
    font-weight: var(--font-normal);
    line-height: 36px;
    padding: 0;
    margin: 0;
  }
}

.owl-carousel .owl-item img {
  max-height: 59px;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
/**dashboard**/
.main-dashboard {
  position: relative;
  z-index: 9;
  background-color: #ebebf0;
  /* &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 366px;
    height: 329px;
    background: url("./assets/images/dashboard/wallet-left-circle.png")
      no-repeat;
    z-index: -1;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 367px;
    height: 367px;
    background: url("./assets/images/dashboard/wallet-right-circle.png")
      no-repeat;
    z-index: -1;
  } */
  & .navbar-nav {
    /* padding: 6px !important;
    border-radius: 12px;
    border: 1.5px solid rgba(245, 210, 85, 0.2);
    background: rgba(245, 210, 85, 0.1); */
  }
  & .nav-item .nav-link {
    /* font-family: var(--font-plus), sans-serif; */
    font-family: var(--font-syne), sans-serif;
    position: relative;
    padding: 0 !important;
    /* border-radius: 8px; */
    /* border: 2px solid transparent; */
    /* background: transparent; */
    color: #4b5563;
    font-size: 14px;
    font-weight: var(--font-bold);
    cursor: pointer;
    text-align: center;
    line-height: 26px;
    &:hover {
      background: var(--Linear, linear-gradient(136deg, #DEBF51 0%, #BD791B 101.3%));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &:hover::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background: var(--Linear, linear-gradient(136deg, #DEBF51 0%, #BD791B 101.3%));
    }
  }
  & .nav-item .nav-link.active {
    /* padding: 5px 19.5px;
    border: 2px solid rgba(245, 210, 85, 0.1);
    background: #f5d255;
    color: #252525;
    width: 100%; */
    background: var(--Linear, linear-gradient(136deg, #DEBF51 0%, #BD791B 101.3%));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background: var(--Linear, linear-gradient(136deg, #DEBF51 0%, #BD791B 101.3%));
    }
  }
  /* & .nav-item:last-child .nav-link.active {
    padding: 5px 34px;
  } */
  & .user-wallet {
    padding: 14px 22px;
    border-radius: 12px;
    border: 2px solid rgba(245, 210, 85, 0.2);
    background: rgba(245, 210, 85, 0.1);
    color: #f5d255;
    font-size: 14px;
    font-family: var(--font-plus), sans-serif;
    font-weight: var(--font-medium);
    display: flex;
    gap: 10px;
    align-items: center;
  }
  & .dropdown-toggle::after {
    display: none;
  }
  & .gap-20 {
    column-gap: 20px;
  }
  & .user-wallet-view {
    padding: 20px !important;
    border-radius: 12px !important;
    border: 0.746px solid rgba(255, 255, 255, 0.4) !important;
    background: #131313 !important;
    backdrop-filter: blur(37.304073333740234px) !important;
    width: 220px !important;
  }
  & .user-profile {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: rgba(68, 68, 68, 1);
    color: #debf51;
    text-align: center;
    font-family: var(--font-plus), sans-serif;
    font-size: 20px;
    font-weight: var(--font-semibold);
  }
  & .userDetail {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  & .userDetail h3 {
    font-size: 14px;
    font-family: var(--font-plus), sans-serif;
    font-weight: var(--font-semibold);
    color: var(--text-white);
    padding: 0 0 10px;
    margin: 0;
  }
  & .userDetail p {
    font-size: 10px;
    font-family: var(--font-plus), sans-serif;
    font-weight: var(--font-normal);
    color: #f5d255;
    padding: 0 0 0px;
    margin: 0;
  }
  & .user-wallet-view li a img {
    display: flex;
    align-items: center;
  }
  & .user-wallet-view li a {
    color: #9ca3af;
    font-family: var(--font-plus), sans-serif;
    font-size: 14px;
    font-weight: var(--font-normal);
    padding: 0;
    margin: 0 0 20px;
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
  }
  & .user-wallet-view li:last-child a {
    margin: 0 0 0px;
  }
  & .user-wallet-view li a:hover {
    background: transparent;
    color: var(--text-primary);
  }
  & .view-show {
    display: none;
  }
  & .not-show {
    display: block;
  }
  & .show .not-show {
    display: none;
  }
  & .show .view-show {
    display: block !important;
  }
}
.dark-mode .main-dashboard{
  background-color: #130c04;
}
.wallet-view {
  height: calc(100vh - 150px);
  align-items: center;
  display: flex;
}
.wallet-header {
  padding: 48px 60px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wallet-header a {
  font-size: 22px;
  line-height: 30px;
  font-weight: var(--font-light);
  color: var(--text-white);
  font-family: var(--font-plus), sans-serif;
  padding: 0;
  margin: 0;
}
.wallet-header a:hover {
  color: var(--text-primary);
  text-decoration: underline;
  text-decoration-color: var(--text-primary);
  text-underline-offset: 5px;
}
.walletview {
  position: relative;
  height: calc(100vh - 74px);
  display: flex;
  align-items: center;

  & h1 {
    color: var(--text-white);
    text-align: center;
    font-size: 30px;
    font-weight: var(--font-semibold);
    line-height: 44px;
    padding: 0 0 10px;
    margin: 0;
  }
  & p {
    font-family: var(--font-plus), sans-serif;
    color: var(--text-grey);
    text-align: center;
    font-size: 18px;
    font-weight: var(--font-normal);
    line-height: 27px;
    padding: 0 0 49px;
    margin: 0;
  }
}
.walletconnect {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: url(./assets/images/lightmode/wallet-center.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
  }
}
.metamask {
  padding: 12px 21px;
  font-family: var(--font-plus), sans-serif;
  color: var(--text-grey-2);
  font-size: 18px;
  font-weight: var(--font-semibold);
  border-radius: 10px;
  border: 2px solid #f3f4f6;
  background: #fff;
  display: flex;
  align-items: center;
  gap: 20px;
  width: fit-content;
  justify-content: center;
  margin: 48px auto 0px;
  cursor: pointer;
  transition: 0.25s ease-in-out;
}
.metamask:hover {
  background: var(--text-primary);
  border-color: transparent;
  transition: 0.25s ease-in-out;
  color: var(--text-white);
}
.wrap {
  height: calc(100vh - 110px);
  display: flex;
  align-items: center;
}
.ellipse-view {
  position: relative;
  z-index: 2;
}
.ellipse-view::before {
  content: "";
  position: absolute;
  top: -110px;
  left: 0;
  width: 100%;
  height: calc(100% + 110px);
  background: url("./assets/images/dashboard/ellipse.png") no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}
.ellipse-view::after {
  content: "";
  position: absolute;
  bottom: 1px;
  left: 0;
  width: 100%;
  height: 607px;
  background: url("./assets/images/dashboard/bottom-img.png");
  background-position: top center;
  background-size: cover;
  z-index: -1;
  background-repeat: repeat-y;
}
.main-dashboard nav {
  /* padding: 16px 24px 0px; */
  padding: 24px 30px 0;
  /* background-color: #ebebf0; */
  
}
/* .dark-mode .main-dashboard nav {
  background-color: #130c04 !important;
} */
.main-dashboard nav .header {
    padding: 0 0 24px 0;
    border-bottom: 1px solid rgba(156, 163, 175, 0.20);
}
.dark-mode .main-dashboard nav .header {
  border-bottom: 1px solid rgba(156, 163, 175, 0.3);
}
/* .main-dashboard .navbar-expand-lg {
  padding: 0;
} */
.main-dashboard .navbar-nav {
  gap: 18px;
}
.cursor-pointer {
  cursor: pointer;
}
.customhline {
  margin: 17px 0px !important;
  color: rgba(255, 255, 255, 0.4) !important;
  border-width: 0.725px;
  backdrop-filter: blur(37.304073333740234px);
  opacity: 1;
}
.pl-2 {
  padding-left: 10px;
}

.banner::after,
body.light-mode .banner:after {
  content: "";
  position: absolute;
  top: -100px;
  right: 0;
  background: url("./assets/images/right-circle.png") no-repeat;
  width: 152px;
  height: 731px;
  z-index: -1;
}
.banner::before,
body.light-mode .banner::before {
  content: "";
  position: absolute;
  top: 80px;
  left: 10%;
  background: url("./assets/images/star-left-first.svg") no-repeat;
  width: 60px;
  height: 60px;
  animation: blink 2s step-start infinite;
}
.banner-2::before,
body.light-mode .banner-2::before {
  content: "";
  position: absolute;
  top: 120px;
  left: -8%;
  background: url("./assets/images/star-left-second.svg") no-repeat;
  width: 30px;
  height: 30px;
  animation: blink 2s step-start infinite;
}
.readdocs::before,
body.light-mode .readdocs::before {
  content: "";
  position: absolute;
  top: -140px;
  left: 0;
  width: 407px;
  height: 733px;
  background: url("./assets/images/left-circle.png") no-repeat;
}

.symbol::after,
body.light-mode .symbol::after {
  content: "";
  position: absolute;
  top: -50px;
  right: 0px;
  background: url("./assets/images/star-left-first.svg") no-repeat;
  width: 60px;
  height: 60px;
  animation: blink 2s step-start infinite;
}
.symbol2::after {
  display: none;
}
body.light-mode .economic-galaxy {
  border: 1px solid #debf51 !important;
}
.banner-title h1 {
  font-size: 56px;
  font-weight: var(--font-semibold);
  color: var(--text-white);
  text-align: center;
  margin: 0;
  padding: 0 0 20px;
}
.banner-title h3 {
  color: var(--text-white);
  font-size: 32px;
  font-weight: var(--font-normal);
  font-variant-numeric: lining-nums;
  line-height: 36px;
  text-align: center;
  padding: 0 0 30px;
  margin: 0 auto;
}

.banner-title p {
  font-family: "Plus Jakarta Sans", sans-serif;
  color: var(--text-secondary);
  font-size: 20px;
  font-weight: var(--font-normal);
  line-height: 33px;
  text-align: center;
  padding: 0;
  margin: 0 auto;
  /* margin: 0 auto 45px; */
  width: 68%;
}
.symbol {
  padding: 60px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  & h5 {
    color: var(--text-secondary);
    text-align: center;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 18px;
    font-weight: var(--font-normal);
    line-height: 26.46px;
    padding: 0 0 8px;
    margin: 0;
  }
  & h3 {
    font-family: var(--font-syne), sans-serif !important;
    font-variant-numeric: lining-nums;
    color: var(--text-white);
    text-align: center;
    font-size: 24px; /**30px*/
    font-weight: var(--font-bold);
    line-height: 26.46px;
    padding: 0;
    margin: 0;
  }
  & a {
    word-break: break-all !important;
  }
}

.aoc-secondary {
  padding: 65px 15px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: linear-gradient(
    136deg,
    rgba(222, 191, 81, 0.04) 0%,
    rgba(189, 121, 27, 0.04) 101.3%
  );
  backdrop-filter: blur(150px);
  transition: 0.25s ease-in-out;

  & h3 {
    font-size: 38px;
    font-weight: var(--font-semibold);
    line-height: 48.26px;
    color: var(--text-white);
    padding: 0 0 20px;
    margin: 0;
  }
  & p {
    color: var(--text-secondary);
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 20px;
    font-weight: var(--font-normal);
    line-height: 30px;
    margin: 0 0 45px;
    padding: 0;
  }
  &:hover {
    transition: 0.25s ease-in-out;
    border: 1px solid #debf51;
  }
}
.ourvison-title {
  padding: 45px 20px 40px 45px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: linear-gradient(
    136deg,
    rgba(222, 191, 81, 0.04) 0%,
    rgba(189, 121, 27, 0.04) 101.3%
  );
  height: 100%;
  transition: 0.25s ease-in-out;
  z-index: 2;
  backdrop-filter: blur(150px);
  & h3 {
    font-size: 38px;
    font-weight: var(--font-semibold);
    line-height: 48.26px;
    color: var(--text-white);
    padding: 0 0 24px;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 15px;
  }
  & p {
    color: var(--text-grey);
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 20px;
    font-weight: var(--font-normal);
    line-height: 30px;
    margin: 0 0 25px;
    padding: 0;
  }
  &:hover {
    transition: 0.25s ease-in-out;
    border: 1px solid #debf51;
  }
  & .visionimg {
    background-blend-mode: color, normal;
    width: 100%;
    position: relative;
    bottom: -40px;
    /* margin: 50px 0px 0px; */
  }
  & .misionimg {
    background-blend-mode: color, normal;
    width: 100%;
    position: relative;
    top: 5px;
    right: -21px;
    margin-bottom: 42px;
  }
}

.presaleinfo {
  padding: 75px 82px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(50px);
  transition: 0.25s ease-in-out;
  & h4 {
    color: var(--text-white);
    font-size: 48px;
    font-weight: var(--font-bold);
    line-height: 60px;
    padding: 0 0 10px;
    margin: 0;
  }
  & p {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 16px;
    font-weight: var(--font-normal);
    line-height: 24px;
    color: var(--text-grey);
    width: 92%;
    margin-bottom: 20px;
    /* padding: 0 0 49px; */
    margin: 0 0 20px;
  }
  & h5 {
    font-variant-numeric: lining-nums proportional-nums;
    color: var(--text-white);
    font-size: 20px;
    font-weight: var(--font-bold);
    line-height: 127%;
    background: var(
      --Linear,
      linear-gradient(136deg, #debf51 0%, #bd791b 101.3%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  & h6 {
    color: var(--text-white);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 40px;
    font-weight: var(--font-bold);
    line-height: 50.8px;
    padding: 0 0 10px;
    margin: 0;
  }
  & span.info {
    font-variant-numeric: lining-nums proportional-nums;
    color: var(--text-white);
    font-size: 20px;
    font-weight: var(--font-bold);
    line-height: 127%;
  }
  & h3 {
    font-family: "Plus Jakarta Sans", sans-serif;
    color: var(--text-grey);
    font-size: 16px;
    font-style: normal;
    font-weight: var(--font-normal);
    line-height: 24px;
    padding: 0 0 5px;
  }
}
.saledetail {
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  & p {
    color: var(--text-grey);
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: var(--font-bold);
    line-height: 140%;
    padding: 0;
    margin: 0 0 15px;
    width: 100%;
  }
  & p span {
    color: var(--text-white);
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: var(--font-bold);
    line-height: 140%;
  }
}

.goals-inner {
  padding: 40px 40px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(50px);
  display: flex;
  flex-direction: column;
  height: 95%;
  transition: 0.25s ease-in-out;
  & h3 {
    color: var(--text-white);
    font-size: 28px;
    font-weight: var(--font-bold);
    line-height: 36px;
    padding: 0 0 13px;
    margin: 0;
  }
  & p {
    font-family: var(--font-plus), sans-serif;
    color: var(--text-secondary);
    font-size: 18px;
    font-weight: var(--font-normal);
    line-height: 27px;
    padding: 0 0 0px;
    margin: 0;
  }
}

.goalofright {
  padding: 40px 26px 40px 40px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(50px);
  margin-bottom: 26px;
  transition: 0.25s ease-in-out;

  & h3 {
    color: var(--text-white);
    font-size: 28px;
    font-weight: var(--font-bold);
    line-height: 36px;
    padding: 0 0 13px;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 15px;
  }
  & p {
    font-family: var(--font-plus), sans-serif;
    color: var(--text-secondary);
    font-size: 18px;
    font-weight: var(--font-normal);
    line-height: 27px;
    padding: 0px;
    margin: 0;
  }
}
.economic-galaxy {
  padding: 25px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.04);
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  transition: 0.25s ease-in-out;
}
.economic-galaxy img {
  border-radius: 20px;
}
.bible-section {
  padding: 32px 26px 11px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(50px);
  transition: 0.25s ease-in-out;
  & h3 {
    color: var(--text-white);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 21px;
    font-weight: var(--font-bold);
    line-height: 28px;
    padding: 0 0 20px;
    margin: 0;
    display: flex;
    align-items: baseline;
    column-gap: 15px;
    row-gap: 0;
  }
  & p {
    font-family: var(--font-plus), sans-serif;
    color: var(--text-secondary);
    font-size: 14px;
    font-weight: var(--font-normal);
    line-height: 21px;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  & p.custom-mb-6 {
    margin-bottom: 43px;
  }
  & p.custom-mb-4 {
    margin-bottom: 30px;
  }
  & p.custom-mb-25 {
    margin-bottom: 25px;
  }
  & .green-life {
    position: relative;
    bottom: 10px;
  }
  & .green-life img {
    width: 100%;
  }
  & .webstorage {
    position: absolute;
    top: 18px;
  }
  & .arrow-bottom {
    position: absolute;
    bottom: 35px;
  }
  & h3 span img {
    width: 16px;
    height: 16px !important;
  }
}
.bible-section.bible-section-6 {
  padding: 32px 25px 10px;
}
.bible-section.bible-section-5 {
  padding: 30px 25px 22px;
}
footer {
  padding: 50px 0px 60px;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(50px);
}

.sociallinkdetail {
  display: flex;
  gap: 18px;
  & li a:hover {
    color: #debf51;
    opacity: 1;
    transition: 0.25s ease-in-out;
  }
  & li a {
    color: var(--text-grey);
    opacity: 0.34;
    transition: 0.25s ease-in-out;
  }
}

.sociallinks h3 {
  color: var(--text-white);
  font-size: 19px;
  font-weight: var(--font-bold);
  line-height: 26px;
  padding: 0 0 30px;
  margin: 0;
}
.sociallinks h4 {
  color: var(--text-white);
  font-size: 19px;
  font-weight: var(--font-bold);
  line-height: 26px;
  padding: 0 0 30px;
  margin: 0;
}

.visitor h3 {
  color: var(--text-white);
  margin: 0; /* Ensure the visitor count text is inline */
  font-size: 19px; /* Adjust the font size as needed */
  font-weight: var(--font-bold); /* Adjust the font weight as needed */
  line-height: 26px; /* Adjust the line height as needed */
  margin: 0; /* Remove any default margin */
}

.footer-head {
  padding: 0 0 38px;
  & h3 {
    color: var(--text-white);
    font-size: 22px;
    font-weight: var(--font-bold);
    line-height: 28px;
    padding: 0;
    margin: 0;
  }
}

.links {
  list-style: none;
  padding: 0;
  margin: 0;
  & li:not(:last-child) {
    padding-bottom: 15px;
  }
  & li a {
    color: var(--text-grey);
    font-family: var(--font-plus), sans-serif;
    font-size: 14px;
    font-weight: var(--font-normal);
    line-height: 21px;
    padding: 0;
    margin: 0;
    transition: 0.25s ease-in-out;
  }
  & li a:hover {
    color: #debf51;
    transition: 0.25s ease-in-out;
    text-decoration: underline !important;
  }
}

.copyrights p {
  color: rgba(255, 255, 255, 0.5);
  font-family: "DM Sans", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.205px;
  padding: 0;
  margin: 0;
}
.bottom-line {
  color: rgba(255, 255, 255, 0.4);
  opacity: 0.5;
  margin: 50px 0px 30px;
}
.mission p {
  color: var(--text-grey);
  margin: 0 0 0px;
}

.modal-body {
  padding: 20px !important;
  border-radius: 36px;
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
  background: #130c04 !important;
}
.custom-modal-head {
  padding: 26px 21px 26px 38px;
  border-radius: 20px;
  border: 1px solid var(--text-primary);
  background: linear-gradient(
    136deg,
    rgba(222, 191, 81, 0.05) 0%,
    rgba(189, 121, 27, 0.05) 101.3%
  );
  backdrop-filter: blur(150px);
  position: relative;
  z-index: 2;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background: url("./assets/images/modal-header-ring.png") no-repeat;
    width: 207px;
    height: 150px;
    z-index: 1;
  }
  & h3 {
    font-size: 26px;
    color: var(--text-white);
    font-weight: 700;
    line-height: 35px;
    padding: 0;
    margin: 0 0 8px;
    font-variant-numeric: lining-nums;
  }
  & h3 span {
    background: linear-gradient(136deg, #debf51 0%, #bd791b 101.3%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h4 {
    font-size: 26px;
    color: var(--lm-text-grey-4);
    font-weight: 700;
    line-height: 37px;
    padding: 10px 0 0;
    margin: 0 0 8px;
    font-variant-numeric: lining-nums;
  }
  & p {
    font-family: var(--font-plus), sans-serif;
    color: var(--text-grey);
    font-size: 16px;
    font-style: normal;
    font-weight: var(--font-normal);
    line-height: 27px;
    padding: 0;
    margin: 0;
  }
  & .close_btn {
    width: 36px;
    height: 36px;
    padding: 6px;
    border-radius: 8px;
    border: 1px solid var(--text-primary);
    background: var(----close-btn-bg);
    color: var(--text-white);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 3;
    transition: 0.25s ease-in-out;
    cursor: pointer;
  }
  & .close_btn:hover {
    background: linear-gradient(136deg, #debf51 0%, #bd791b 101.3%) !important;
    color: var(--text-white);
    transition: 0.25s ease-in-out;
  }
}
.custom-modal-body {
  padding: 30px 0px 0px;
  & ul {
    padding: 0px 38px 0px;
    margin: 0;
    list-style: none;
  }
  & li {
    font-family: var(--font-plus), sans-serif;
    color: var(--text-grey);
    font-size: 18px;
    font-weight: var(--font-normal);
    line-height: 30px;
    padding: 0 38px 30px;
    margin: 0;
  }
  & li:last-child {
    padding: 0 38px 0;
  }
  & .bible-modal-wrap {
    border-radius: 20px;
    padding: 35px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(
      136deg,
      rgba(222, 191, 81, 0.05) 0%,
      rgba(189, 121, 27, 0.05) 101.3%
    );
    backdrop-filter: blur(150px);
    margin-bottom: 17px;
  }
  & .bible-modal-wrap img {
    border-radius: 10px;
  }
  & h3 {
    color: var(--text-white);
    text-align: center;
    font-family: var(--font-plus), sans-serif;
    font-size: 18px;
    font-weight: var(--font-normal);
    line-height: 30px;
    padding: 0 15px;
    margin: 0;
  }
}
.switches-container {
  width: 74px;
  height: 37px;
  display: flex;
  align-items: center;
  padding: 0;
  position: relative;
  background: var(--switches-bg-color);
  border: 1px solid #debf51;
  line-height: 3rem;
  border-radius: 3rem;
}
.btn.translation {
  border: 1px solid var(--lm-text-grey-2);
  font-size: 16px;
  padding: 5px 10px;
  color: var(--text-white);
  transition: 0.25s ease-in-out;
  &:hover,
  &:focus {
    border: 1px solid var(--text-primary);
    color: var(--text-primary);
    transition: 0.25s ease-in-out;
  }
  & .dropdown:active {
    background-color: var(--text-primary) !important;
    color: var(--text-white);
  }
}

.btn-closes {
  background: transparent;
  border: 0;
  color: var(--closes);
  font-size: 20px;
  font-weight: 700;
}
/* input (radio) for toggling. hidden - use labels for clicking on */
.switches-container input {
  visibility: hidden;
  position: absolute;
  top: 0;
}

/* labels for the input (radio) boxes - something to click on */
.switches-container label {
  width: 50%;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: var(--switches-label-color);
  font-size: 22px;
}

/* switch highlighters wrapper (sliding left / right)
      - need wrapper to enable the even margins around the highlight box
  */
.switch-wrapper {
  position: absolute;
  top: 0;
  bottom: -1px;
  width: 50%;
  padding: 3px;
  z-index: 3;
  transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  /* transition: transform 1s; */
}

/* switch box highlighter */
.switch {
  border-radius: 3rem;
  background: var(--switch-bg-color);
  height: 100%;
}
.light_moon em {
  color: var(--text-white);
  opacity: 0.5;
  position: relative;
  top: 2px;
}
.dark-mode .light_moon em {
  opacity: 1;
}
/* switch box labels
      - default setup
      - toggle afterwards based on radio:checked status
  */
.switch div {
  width: 100%;
  text-align: center;
  opacity: 0;
  display: block;
  color: var(--switch-text-color);
  transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
  will-change: opacity;
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}

/* slide the switch box from right to left */
.switches-container input:nth-of-type(1):checked ~ .switch-wrapper {
  transform: translateX(0%);
}

/* slide the switch box from left to right */
.switches-container input:nth-of-type(2):checked ~ .switch-wrapper {
  transform: translateX(100%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
.switches-container
  input:nth-of-type(1):checked
  ~ .switch-wrapper
  .switch
  div:nth-of-type(1) {
  opacity: 1;
}

/* toggle the switch box labels - second checkbox:checked - show second switch div */
.switches-container
  input:nth-of-type(2):checked
  ~ .switch-wrapper
  .switch
  div:nth-of-type(2) {
  opacity: 1;
}
/**signup**/
.wallet-innerwrap {
  padding: 58px 68px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  border: 0.8px solid #fff;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.08) 5.65%,
    rgba(255, 255, 255, 0.2) 94.35%
  );
  backdrop-filter: blur(8.728915214538574px);

  & .form-group {
    margin-bottom: 22px;
    & p {
      padding-bottom: 15px;
    }
    & p:last-child {
      padding-bottom: 0;
    }
  }
  & label {
    font-family: var(--font-plus), sans-serif;
    font-size: 16px;
    color: var(--text-white);
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 10px;
    padding: 0;
  }
  & .signup-mandatory {
    color: var(--text-white);
  }
  & label span {
    font-family: var(--font-plus), sans-serif;
    font-size: 16px;
    color: var(--text-white);
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 10px;
    padding: 0;
  }
  & .form-control {
    padding: 14px 16px 14px 22px;
    border-radius: 8px;
    border: 0.8px solid rgba(255, 255, 255, 0.35);
    background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.06) 5.65%,
      rgba(255, 255, 255, 0.15) 94.35%
    );
    color: rgba(255, 255, 255, 1);
    font-family: var(--font-plus), sans-serif;
    font-size: 14px;
  }

  & .form-control:focus {
    outline: 0;
    background: transparent;
    color: #fff;
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.15);
    border-color: transparent;
  }
  & h4 {
    color: var(--text-white);
    font-size: 28px;
    font-weight: var(--font-semibold);
    padding: 0 0 14px;
    margin: 0;
  }
  & p {
    color: var(--text-secondary);
    font-family: var(--font-plus), sans-serif;
    font-size: 14px;
    font-weight: var(--font-normal);
    line-height: 20.58px;
    padding: 0 0 25px;
    margin: 0;
  }
  & .phonenumber {
    position: absolute;
    top: 10px;
    width: 70px;
    left: 16px;
    background: transparent;
    color: var(--text-white);
    border-width: 0 1px 0 0;
    border-color: rgba(255, 255, 255, 0.35);
    border-radius: 0;
    padding: 4px 0px 4px 0px;
  }
  & .phone-nospace input {
    padding-left: 100px;
  }

  & .signups {
    font-family: var(--font-plus), sans-serif;
    padding: 14px 62px;
    border-radius: 12px;
    background: #f5d255;
    border: 1px solid transparent;
    color: #252525;
    text-align: center;
    font-size: 18px;
    font-weight: var(--font-semibold);
    cursor: pointer;
    transition: 0.25s ease-in-out;
  }
  & .signups:hover,
  .signups:focus {
    background: var(--text-primary);
    transition: 0.25s ease-in-out;
  }
  & .gap-65 {
    column-gap: 65px;
    row-gap: 20px;
  }
  & .form-check {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  & .form-bottom {
    display: flex;
    align-items: center;
  }
  & .form-select {
    background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>")
      no-repeat;
    background-position: right 5px top 50%;
    color: #fff !important;
    background-color: transparent !important;
  }
  & .form-select:focus option:checked {
    background: var(--text-primary);
    padding: 5px !important;
    color: #1f2a37;
  }
  & .form-select:hover option {
    background: var(--switch-text-color);
    padding: 5px !important;
    color: #1f2a37;
  }
  & .form-select:focus {
    box-shadow: none;
    border-color: none !important;
  }
  & input[type="checkbox"] {
    width: 17px;
    height: 17px;
    border-radius: 2px;
    border: 0.8px solid rgba(255, 255, 255, 0.5);
    /* background: linear-gradient(45deg, rgba(255, 255, 255, 0.00) 5.65%, rgba(255, 255, 255, 0.00) 94.35%); */
  }
  .form-check-input:checked {
    background-color: var(--text-primary) !important;
    box-shadow: none;
  }
  .form-check-input:focus {
    box-shadow: none;
  }
  & .form-check-label {
    color: rgba(255, 255, 255, 0.6);
    font-family: var(--font-plus), sans-serif;
    font-size: 14px;
    font-weight: var(--font-normal);
    line-height: 20px;
    margin-bottom: 0;
    padding-left: 10px;
  }
  & .form-check-label a {
    color: var(--text-white);
    font-family: var(--font-plus), sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: var(--font-normal);
    line-height: 20px;
    cursor: pointer;
  }
}

input:-internal-autofill-selected,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px #ffffff22 inset !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: #ffffff;
}
body.light-mode input:-internal-autofill-selected,
body.light-mode input:-webkit-autofill {
  -webkit-text-fill-color: var(--lm-text-grey);
}

.country-code {
  position: absolute;
  top: 10px;
  width: 58px !important;
  left: 16px;
  border-radius: 0;
  padding: 2px 0px !important;
  background: transparent !important;
  border: 0 !important;
  font-family: var(--font-plus), sans-serif !important;
  font-size: 20px !important;
  font-weight: var(--font-medium) !important;
  color: var(--text-white) !important;
}
.country-code:hover,
.country-code:focus {
  box-shadow: none !important;
  outline: none !important;
}
.c-code .form-control {
  padding-left: 75px !important;
}

/**dashboard**/
.wallet-detailed-view {
  padding: 50px;
  border-radius: 10px;
  border: 1.2px solid #313235;
  background: #181818;
  & .dashboard-card {
    padding: 12px;
    border-radius: 8px;
    border: 1.449px solid #313235;
    display: flex;
    align-items: center;
    gap: 16px;
    height: 100%;
  }
  & .dashboard-card h3 {
    color: #d9d9d9;
    font-family: var(--font-plus), sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: var(--font-medium);
    line-height: 17px;
    padding: 0 0 3px;
    margin: 0;
  }
  & .dashboard-card h4 {
    color: #d9d9d9;
    font-family: var(--font-plus), sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: var(--font-semibold);
    line-height: 28px;
    word-break: break-all;
    padding: 0;
    margin: 0;
  }
}
.dashboard-head-wrap {
  padding: 40px 0px 16px;
  & h3 {
    color: var(--text-white);
    font-size: 22px;
    font-weight: var(--font-medium);
    font-variant-numeric: lining-nums;
    line-height: 33px;
    padding: 0;
    margin: 0;
  }
}

/* table {
  border-radius: 12px;
  border-top: 0px solid transparent;
  border-collapse: separate;
} */
/* table > thead th {
  padding: 14px 20px !important;
  color: #d9d9d9;
  text-align: center;
  font-family: var(--font-plus), sans-serif;
  font-size: 14px;
  font-weight: var(--font-normal);
  line-height: 32px;
  border-bottom: 1px solid #313235;
  background: #252525 !important;
} */
/* .table > :not(caption) > * {
  color: #ededed;
  text-align: center;
  font-family: var(--font-plus), sans-serif;
  font-size: 14px;
  font-weight: var(--font-normal);
  line-height: 27px;
  padding: 14px 20px !important;
  background-color: #1e1e1e;
  border-bottom: 1px solid #313235 !important;
  font-variant-numeric: lining-nums;
  white-space: nowrap;
} */
/* 
.table {
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #313235 !important;
}

.table td {
  padding: 10px;
}

.table > :not(caption) > * > * {
  color: #ededed !important;
  text-align: center;
  font-family: var(--font-plus), sans-serif;
  font-size: 14px;
  font-weight: var(--font-normal);
  line-height: 31px;
  padding: 14px 20px !important;
  background-color: rgba(37, 37, 37, 1);
  border-bottom: 1px solid #313235 !important;
  font-variant-numeric: lining-nums;
  box-shadow: none !important;
}
.table thead th:first-child {
  border-radius: 12px 0 0;
}
.table thead th:last-child {
  border-radius: 0px 12px 0 0px;
}
.table tfoot tr td:first-child {
  border-radius: 0 0 0 12px;
}
.table tfoot tr td:last-child {
  border-radius: 0 0 12px 0;
}
.table tfoot tr td {
  color: #ededed;
  text-align: center;
  font-family: var(--font-plus), sans-serif;
  font-size: 14px;
  font-weight: var(--font-normal);
  line-height: 32px;
  padding: 14px 20px !important;
  background-color: #252525 !important;
  border-bottom-width: 0px !important;
  font-variant-numeric: lining-nums;
}
.table.table-striped > tbody > tr:nth-of-type(odd) > * {
  background: #181818 !important;
}

.table.table-striped > tbody > tr:nth-of-type(even) > * {
  background: #1e1e1e !important;
}
input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6) !important;
  font-size: 14px;
} */
/**buynow screen**/
.buynow {
  padding: 20px 30px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(50px);
  margin-bottom: 30px;

  & h1 {
    color: var(--text-white);
    font-variant-numeric: lining-nums;
    font-size: 30px;
    font-style: normal;
    font-weight: var(--font-bold);
    line-height: 40px;
    padding: 0 0 5px;
    margin: 0;
  }
  & p {
    font-family: var(--font-plus), sans-serif;
    color: #bfc0d2;
    font-size: 20px;
    font-weight: var(--font-normal);
    line-height: 30px;
    padding: 0 0 0px;
    margin: 0;
  }

  & .counting-view {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 35px;
  }
  & .counting p {
    color: var(--text-primary);
    font-family: var(--font-plus), sans-serif;
    font-size: 16px;
    font-weight: var(--font-normal);
    line-height: 20px;
    text-align: center;
    text-transform: capitalize;
    padding: 0;
    margin: 0;
  }
  & .counting h3 {
    border-radius: 12px;
    border: 1.5px solid rgba(245, 210, 85, 0.2);
    background: rgba(245, 210, 85, 0.1);
    font-family: var(--font-plus), sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: var(--font-bold);
    width: 65px;
    height: 65px;
    color: var(--text-white);
    display: flex;
    align-items: center;
    justify-content: center;
    font-variant-numeric: lining-nums;
  }
}
.presale {
  padding: 15px 25px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(50px);
  & h3 {
    color: var(--text-white);
    font-size: 24px;
    font-style: normal;
    font-weight: var(--font-bold);
    font-variant-numeric: lining-nums;
    line-height: 30px;
    padding: 0 0 5px;
    margin: 0;
  }
  & h4 {
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 18px;
    font-style: normal;
    font-weight: var(--font-normal);
    line-height: 23px;
    background: linear-gradient(136deg, #debf51 0%, #bd791b 101.3%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 0;
    margin: 0;
  }
  & h5 {
    color: var(--text-white);
    font-family: var(--font-plus), sans-serif;
    font-size: 20px;
    font-weight: var(--font-medium);
    letter-spacing: -0.06px;
    padding: 0 0 10px;
    margin: 0;
  }
  & p {
    font-family: var(--font-plus), sans-serif;
    color: #bfc0d2;
    font-size: 16px;
    font-weight: var(--font-normal);
    line-height: 20px;
    padding: 0 0 0px;
    margin: 0;
  }
  & p span {
    color: var(--text-white);
    font-weight: var(--font-semibold);
  }
  & .usdt-details {
    padding: 19px 0px 0px;
    display: flex;
    align-items: center;
    gap: 70px;
    row-gap: 10px;
    flex-wrap: wrap;
  }
  & .usdt-details h5 {
    color: #bfc0d2;
    font-family: var(--font-plus), sans-serif;
    font-size: 14px;
    font-weight: var(--font-light);
    line-height: 21px;
    padding: 0;
    margin: 0;
  }
  & .usdt-details h6 {
    color: var(--text-white);
    font-family: var(--font-plus), sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: var(--font-bold);
    line-height: 150%;
    padding: 0;
    margin: 0;
  }
  & .usdtdata {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.06);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px 12px 30px;
    margin: 0 0 5px;
  }

  & .usdtdata h3 {
    color: var(--text-white);
    font-family: var(--font-plus), sans-serif;
    font-size: 12px !important;
    font-weight: var(--font-normal);
    line-height: 18px;
    padding: 0;
    margin: 0;
  }
  & .usdtdata h6 {
    color: var(--text-white);
    font-family: var(--font-plus), sans-serif;
    font-size: 12px !important;
    font-weight: var(--font-normal);
    word-break: break-word;
    line-height: 18px;
    text-align: right;
    padding: 7px 0 0;
    margin: 0;
  }
  & .usdtdata input {
    background: none;
    border: 0;
    color: rgba(255, 255, 255, 1);
    font-family: "ProximaNovaFont", sans-serif;
    font-size: 35px;
    font-weight: var(--font-bold);
    width: 100%;
  }
  & .usdtdata input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  & .usdtdata input:focus {
    box-shadow: none !important;
    border: 0px solid !important;
    outline: 0px solid !important;
  }
  & .usdtdata input:focus-visible {
    box-shadow: none !important;
    border: 0px solid !important;
    outline: 0px solid !important;
  }
  & .static-form {
    padding: 20px 0px;
  }

  & .usdtbtn {
    font-family: "Montserrat", sans-serif;
    padding: 8px 12px;
    font-size: 16px;
    font-weight: var(--font-normal);
    color: var(--text-white);
    border-radius: 10px;
    border: 1px solid #53ae94;
    background: rgba(83, 174, 148, 0.2);
    backdrop-filter: blur(50px);
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 10px;
    width: fit-content;
    cursor: pointer;
    transition: 0.25s ease-in-out;
  }
  & .usdtbtn:hover {
    transition: 0.25s ease-in-out;
    background: rgba(83, 174, 148, 0.5);
  }
  & .usdtbtn:focus {
    transition: 0.25s ease-in-out;
    background: rgba(83, 174, 148, 0.5);
  }
  & .aocbtn {
    font-family: "Montserrat", sans-serif;
    padding: 8px 12px;
    font-size: 18px;
    font-weight: var(--font-normal);
    color: var(--text-white);
    border-radius: 10px;
    border: 1px solid #f5d255;
    background: rgba(245, 210, 85, 0.1);
    backdrop-filter: blur(50px);
    display: flex;
    align-items: center;
    gap: 10px;
    width: fit-content;
    cursor: pointer;
    transition: 0.25s ease-in-out;
    white-space: nowrap;
  }
  & .aocbtn:hover {
    transition: 0.25s ease-in-out;
    background: rgba(245, 210, 85, 0.5);
  }
  & .aocbtn:focus {
    transition: 0.25s ease-in-out;
    background: rgba(245, 210, 85, 0.5);
  }
  & .buytoken {
    font-family: "ProximaNovaFont", sans-serif;
    border-radius: 12px;
    border: 1px solid rgba(245, 210, 85, 0.2);
    background: rgba(245, 210, 85, 0.1);
    padding: 10px;
    width: 100%;
    display: block;
    font-size: 20px;
    font-weight: var(--font-bold);
    color: #fff;
    text-align: center;
    transition: 0.25s ease-in-out;
    text-decoration: none;
    cursor: pointer;
  }
  & .claim {
    font-family: "ProximaNovaFont", sans-serif;
    border-radius: 12px;
    border: 1px solid rgba(245, 210, 85, 0.2);
    background: rgba(245, 210, 85, 0.1);
    padding: 10px;
    width: 150px;
    font-size: 20px;
    font-weight: var(--font-bold);
    color: #fff;
    text-align: center;
    transition: 0.25s ease-in-out;
    text-decoration: none;
    cursor: pointer;
  }
  & .buytoken:hover {
    transition: 0.25s ease-in-out;
    background: rgba(245, 210, 85, 0.5);
  }
  & .buytoken:focus {
    transition: 0.25s ease-in-out;
    background: rgba(245, 210, 85, 0.5);
  }
  & .claim:hover {
    transition: 0.25s ease-in-out;
    background: rgba(245, 210, 85, 0.5);
  }
  & .claim:focus {
    transition: 0.25s ease-in-out;
    background: rgba(245, 210, 85, 0.5);
  }
  & .walletaddress {
    display: flex;
    align-items: baseline;
    column-gap: 14px;
  }

  & .lines {
    position: relative;
    width: 335px;
    height: 1px;
    background: linear-gradient(
      233deg,
      rgba(255, 255, 255, 0.4) 33.93%,
      rgba(255, 255, 255, 0) 134.13%
    );
  }
  & .dots {
    width: 10px;
    height: 10px;
    display: inline-flex;
    background: white;
    border-radius: 100%;
    position: absolute;
    top: -5px;
    right: 0;
  }
}
/** profile**/
.profile {
  & li a {
    padding: 17px 21px;
    border-radius: 8px;
    border: 2px solid transparent;
    background: transparent;
    color: #9ca3af;
    font-family: var(--font-plus), sans-serif;
    font-size: 19px;
    font-weight: var(--font-normal);
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 21px;
  }
  & li a:hover {
    border: 2px solid rgba(245, 210, 85, 0.2);
    background: rgba(245, 210, 85, 0.1);
    color: var(--text-primary);
  }
  & li a.active {
    border: 2px solid rgba(245, 210, 85, 0.2) !important;
    background: rgba(245, 210, 85, 0.1) !important;
    color: var(--text-primary);
  }
  & li:not(:last-child) {
    margin-bottom: 10px;
  }
}
.profile-view {
  padding: 0px 26px 0px 80px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    background: #313235;
    width: 1px;
    left: 15px;
    transform: translateX(15px);
    /* height: 100%; */
  }
  & h1 {
    color: var(--text-white);
    font-size: 32px;
    font-weight: var(--font-bold);
    line-height: 41px;
    padding: 0 0 15px;
    margin: 0;
  }
  & p {
    color: #b0b0b0;
    font-family: var(--font-plus);
    font-size: 16px;
    font-weight: var(--font-normal);
    line-height: 24px;
    padding: 0 0 45px;
    margin: 0;
  }
  & label {
    color: #b0b0b0;
    font-family: var(--font-plus), sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: var(--font-normal);
    line-height: 26px;
    padding: 0 0 10px;
    margin: 0;
  }
  & .form-control {
    padding: 11px 16px;
    border-radius: 8px;
    border: 0.8px solid rgba(255, 255, 255, 0.35);
    background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.06) 5.65%,
      rgba(255, 255, 255, 0.15) 94.35%
    );
    color: var(--text-white);
    font-family: var(--font-plus), sans-serif;
    font-size: 20px;
    font-weight: var(--font-medium);
    margin-bottom: 10px;
    width: 100%;
  }
  & .form-control:focus {
    box-shadow: none;
    border-color: transparent !important;
    background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.06) 5.65%,
      rgba(255, 255, 255, 0.15) 94.35%
    );
    color: var(--text-white);
  }
  & .form-group {
    padding-right: 0px;
  }
  .form-control:disabled {
    background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.06) 5.65%,
      rgba(255, 255, 255, 0.15) 94.35%
    );
    cursor: not-allowed;
  }
}
.profile-edit {
  & h3 {
    color: #b0b0b0;
    font-family: var(--font-plus), sans-serif;
    font-size: 18px;
    font-weight: var(--font-normal);
    line-height: 26px;
    margin: 0;
    padding: 0 0 10px;
  }
  & .profile-img {
    width: 170px;
    height: 170px;
    border-radius: 100%;
    outline: 10px solid #313235;
    outline-offset: 0px;
    background: #444;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    color: #debf51;
    font-family: var(--font-plus), sans-serif;
    font-size: 80px;
    font-style: normal;
    font-weight: var(--font-medium);
  }
}
.socail-share ul {
  list-style: none;
  display: flex;
  gap: 5px;
  & li a {
    width: 51px;
    height: 51px;
    border-radius: 10px;
    border: 1px solid #374151;
    background: #181818;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9ca3af;
    transition: 0.25 ease-in-out;
  }
  & li a:hover {
    color: #debf51;
    transition: 0.25 ease-in-out;
    background: #374151;
  }
}
@keyframes blink {
  50% {
    opacity: 0;
  }
}

/**light mode and darkmode**/
body.dark-mode {
  background: url(assets/images/bg.png) #130c04 !important;
}
/* body.light-mode {
  background: #f2f2f7 !important;
} */

/* body.light-mode .navbar-expand-lg .navbar-nav .nav-item .nav-link {
  color: #4b5563 !important;
} */
body.light-mode .header {
  border-bottom: 2px solid rgba(156, 163, 175, 0.2) !important;
}
body.light-mode .alpha {
  background: linear-gradient(
    136deg,
    rgba(222, 191, 81, 0.15) 0%,
    rgba(189, 121, 27, 0.15) 101.3%
  );
  color: #374151;
}

body.light-mode .alpha:hover {
  background: linear-gradient(
    136deg,
    rgba(222, 191, 81, 1) 0%,
    rgba(189, 121, 27, 1) 101.3%
  );
  color: #fff;
}
body.light-mode .alpha:hover .arrow, .dark-mode .alpha:hover .arrow {
  color: #374151;
}

body.light-mode .banner:after {
  background: url("./assets/images/lightmode/lmode-right-circle.png") !important;
}
body.light-mode .banner::before {
  background: url("./assets/images/lightmode/Star-left.svg") !important;
}
body.light-mode .banner-2::before {
  background: url("./assets/images/lightmode/Star-left-2.svg") no-repeat;
}

body.light-mode .readdocs::before {
  background: url("./assets/images/lightmode/lmode-left-circle.png") no-repeat;
}
body.light-mode .symbol::after {
  background: url("./assets/images/lightmode/Star3.svg") no-repeat;
}
body.light-mode .banner-title h1,
body.light-mode .app-section h2,
body.light-mode .banner-title h3,
body.light-mode .readdocs .bep20-title {
  color: var(--light-mode-text) !important;
}

body.light-mode .app-section p,
body.light-mode .banner-title p {
  color: var(--lm-text-grey);
}

body.light-mode .read-btn {
  border: 1px solid #debf51;
  color: var(--lm-text-grey);
  opacity: 0.85;
}
body.light-mode .symbol {
  & h5 {
    color: var(--lm-text-grey-2);
  }
  & h3 {
    color: var(--lm-text-grey-3);
  }
}
body.light-mode .aoc-secondary {
  border: 1px solid var(--text-primary);
  & h3 {
    color: var(--lm-text-black-2);
  }
  & p {
    color: var(--lm-text-grey-3);
  }
}
body.light-mode .common-heading {
  & h3 {
    color: var(--lm-text-black-2);
  }
  & p {
    color: var(--lm-text-grey-3);
  }
}
body.dark-mode .marquee {
  & h3 {
    color: var(--text-white);
  }
  & h4 {
    color: var(--text-white);
  }
}
body.light-mode .ourvison-title {
  border: 1px solid #debf51;
  background: linear-gradient(
    136deg,
    rgba(222, 191, 81, 0.05) 0%,
    rgba(189, 121, 27, 0.05) 101.3%
  );
  backdrop-filter: blur(150px);
  & h3 {
    color: var(--light-mode-text);
  }
  & p {
    color: var(--lm-text-grey-3);
  }
}
body.light-mode .mission p {
  color: var(--lm-text-grey-3);
}
body.light-mode .presaleinfo {
  border: 1px solid #debf51;
  background: linear-gradient(
    136deg,
    rgba(222, 191, 81, 0.05) 0%,
    rgba(189, 121, 27, 0.05) 101.3%
  );
  backdrop-filter: blur(150px);
  & h4 {
    color: var(--light-mode-text);
  }
  & p {
    color: var(--lm-text-grey-3);
    margin-bottom: 20px;
  }
  & h6 {
    color: var(--light-mode-text);
  }
  & h3 {
    color: var(--lm-text-grey-3);
  }
  & span.info {
    color: var(--lm-text-black-2);
  }
  & h5 {
    font-variant-numeric: lining-nums proportional-nums;
    color: var(--text-white);
    font-size: 20px;
    font-weight: var(--font-bold);
    line-height: 127%;
    background: var(
      --Linear,
      linear-gradient(136deg, #debf51 0%, #bd791b 101.3%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
body.light-mode .saledetail {
  & p {
    color: var(--lm-text-grey);
  }
  & p span {
    color: var(--light-mode-text);
  }
}

body.light-mode .goals-inner {
  border: 1px solid #debf51;
  background: linear-gradient(
    136deg,
    rgba(222, 191, 81, 0.05) 0%,
    rgba(189, 121, 27, 0.05) 101.3%
  );
  backdrop-filter: blur(150px);
  & h3 {
    color: var(--light-mode-text);
  }
  & p {
    color: var(--lm-text-grey-3);
  }
}
body.light-mode .goalofright {
  border: 1px solid #debf51;
  background: linear-gradient(
    136deg,
    rgba(222, 191, 81, 0.05) 0%,
    rgba(189, 121, 27, 0.05) 101.3%
  );
  backdrop-filter: blur(150px);
  & h3 {
    color: var(--lm-text-black-2);
  }
  & p {
    color: var(--lm-text-grey);
  }
}
body.light-mode .copyrights .terms a {
  color: rgba(31, 42, 55, 0.5) !important;
}
body.light-mode .copyrights .terms a:hover {
  color: rgba(31, 42, 55, 1) !important;
}
body.light-mode .bible-section {
  border: 1px solid #debf51;
  background: linear-gradient(
    136deg,
    rgba(222, 191, 81, 0.05) 0%,
    rgba(189, 121, 27, 0.05) 101.3%
  );
  backdrop-filter: blur(150px);
  & h3 {
    color: var(--light-mode-text);
  }
  & p {
    color: var(--lm-text-grey-3);
  }
}

body.light-mode footer {
  border-top: 1px solid #debf51;
  background: linear-gradient(
    136deg,
    rgba(222, 191, 81, 0.05) 0%,
    rgba(189, 121, 27, 0.05) 101.3%
  );
}

body.light-mode .sociallinkdetail {
  & li a:hover {
    color: var(--text-primary);
  }
  & li a {
    color: var(--lm-text-grey-2);
    opacity: 1;
  }
}
body.light-mode .sociallinks h3 {
  color: var(--lm-text-black-2);
}
body.light-mode .sociallinks h4 {
  color: var(--lm-text-black-2);
}

body.light-mode .visitor h3 {
  color: var(--lm-text-black-2);
}

body.light-mode .footer-head {
  padding: 0 0 20px;
  & h3 {
    color: var(--lm-text-black-2);
  }
}
body.light-mode .links {
  & li a {
    color: var(--lm-text-grey);
  }
  & li a:hover {
    color: var(--text-grey-2);
  }
}
body.light-mode .bottom-line {
  color: #c8c8c8;
  opacity: 1;
}
body.light-mode .copyrights p {
  color: rgba(31, 42, 55, 0.5);
}
/**dashboard header**/
body.light-mode .walletview {
  & h1 {
    color: var(--lm-text-black-2);
  }
  & p {
    color: var(--lm-text-grey-3);
  }
}
body.light-mode .walletconnect {
  &:after {
    background: url("./assets/images/lightmode/wallet-center.png");
  }
}
/*
  .light-view .main-dashboard{
    &:before{
      display: none;
      background:url(./assets/images/lightmode/dashboard-left-side.png);
    }
    &::after{
      display: none;
    }
  } */
.light-view .temp-navbar {
  visibility: hidden;
}
body.light-mode .light-view .main-dashboard {
  &:before {
    display: none;
  }
  &:after {
    display: none;
  }
}
body.dark-mode .light-view .main-dashboard {
  &:before {
    display: none;
  }
  &:after {
    display: none;
  }
}
body.light-mode .main-dashboard {
  &:before {
    display: none;
    background: url(./assets/images/lightmode/dashboard-left-side.png);
  }
  & .user-wallet {
    border: 2px solid #f5d255;
    background: rgba(245, 210, 85, 0.32);
    color: var(--lm-text-black-2);
  }
  & .user-wallet-view {
    border: 1px solid rgba(156, 163, 175, 0.45) !important;
    background: #f5f5f8 !important;
  }
  & .user-profile {
    background: #e5e5ea;
    color: #4b5563;
  }
  & .userDetail h3 {
    color: var(--lm-text-black-2);
  }
  & .userDetail p {
    color: #c9932f;
  }
  & .customhline {
    color: #9ca3af !important;
  }
  & .user-wallet-view li a {
    color: var(--lm-text-grey-3);
  }
  & .user-wallet-view li a:hover {
    color: var(--text-primary);
  }
}

body.light-mode .switches-container {
  border: 1px solid var(--text-primary);
  background: var(--lm-switch-bg-color) !important;
}
body.light-mode .light_moon i {
  color: var(--text-primary);
  opacity: 1;
}
body.light-mode .light_moon em {
  background: var(--light-mode-active);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 1;
}
/**signup**/
body.light-mode .wallet-innerwrap {
  border-radius: 20px;
  border: 0.8px solid rgba(156, 163, 175, 0.47);
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.56) 5.65%,
    rgba(255, 255, 255, 0.49) 94.35%
  );
  backdrop-filter: blur(8.728915214538574px);

  & label span {
    color: #374151;
  }
  & .signup-mandatory {
    color: #374151;
  }
  & h4 {
    color: var(--lm-text-black-2);
  }
  & p {
    color: var(--lm-text-grey);
  }
  & label {
    color: var(--lm-label);
    color: #374151;
  }
  & .form-control {
    border: 1px solid rgba(156, 163, 175, 0.45);
    background: rgba(245, 245, 248, 0.75);
    color: var(--lm-text-grey);
  }
  & .phonenumber {
    color: var(--lm-text-black-2);
    border-color: rgba(156, 163, 175, 0.45);
  }
  & .form-select {
    background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='currentColor'/></g></svg>")
      no-repeat;
    background-position: right 5px top 50%;
    color: #1f2a37 !important;
    padding: 5px !important;
  }
  & .phonenumber {
    font-variant-numeric: lining-nums;
  }
  & .phonenumber option {
    font-variant-numeric: lining-nums;
  }
  & input {
    border: 0.8px solid #9ca3af;
    /* background: var(--text-white); */
  }
  & .form-check-label {
    color: var(--lm-text-grey);
  }
  & .form-check-label a {
    color: var(--lm-text-black-2);
  }
}
.form-control.form-select,
.form-control.form-select:focus {
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.06) 5.65%,
    rgba(255, 255, 255, 0.15) 94.35%
  );
  border: 0.8px solid rgba(255, 255, 255, 0.35) !important;
}
body.light-mode .form-control.form-select {
  border: 1px solid rgba(156, 163, 175, 0.45) !important;
  padding: 14px 16px 14px 22px !important;
  background: rgba(245, 245, 248, 0.75) !important;
}
body.light-mode input::-webkit-input-placeholder {
  color: var(--lm-placeholder) !important;
  font-size: 14px;
}
/**dashboard**/
body.light-mode .wallet-detailed-view {
  border-radius: 10px;
  border: 1.2px solid#E5E5EA;
  background: var(--text-white);
  & .dashboard-card {
    border: 1.449px solid #e5e5ea;
  }
  & .dashboard-card h3 {
    color: var(--lm-text-grey-2);
  }
  & .dashboard-card h4 {
    color: var(--lm-text-black-2);
  }
}

body.light-mode .wallet-detailed-view {
  border-radius: 10px;
  border: 1.2px solid#E5E5EA;
  background: var(--text-white);
  & .claim-font {
    border: 1.449px solid #e5e5ea;
  }
  & .claim-font h3 {
    color: var(--lm-text-grey-2);
  }
  & .claim-font h4 {
    color: var(--lm-text-black-2);
  }
}
/* body.light-mode .table > :not(caption) > * {
  color: var(--lm-text-black-2);
  background-color: #f2f2f7 !important;
  border-bottom: 1px solid #d1d1d6 !important;
}
body.light-mode .table > :not(caption) > * > * {
  border-bottom: 1px solid #d1d1d6 !important;
}
body.light-mode .table > :not(caption) > * > * {
  color: var(--lm-text-grey-3) !important;
  background: transparent !important;
} */
body.light-mode .dashboard-head-wrap {
  & h3 {
    color: var(--lm-text-black-2);
  }
}
/* body.light-mode table {
  border: 1.2px solid #d1d1d6 !important;
  border-collapse: separate;
  border-radius: 12px;
}
body.light-mode .table-token {
  border: 0 !important;
}
body.light-mode .table.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: var(--text-white) !important;
}
body.light-mode .table tfoot tr td {
  border-bottom: 0 !important;
}
body.light-mode .table.table-striped > tbody > tr:nth-of-type(even) > * {
  background-color: var(--lm-table-even) !important;
}
body.light-mode .table tfoot tr td {
  background: #f2f2f7 !important;
  color: var(--lm-text-black-2);
} */
/**buy now **/
body.light-mode .buynow {
  border-radius: 20px;
  border: 1px solid rgba(156, 163, 175, 0.45);
  background: #f5f5f8;
  & h1 {
    color: var(--lm-text-black-2);
  }
  & p {
    color: var(--lm-text-grey-3);
  }
  & .counting h3 {
    border: 2px solid #f5d255;
    background: rgba(245, 210, 85, 0.32);
    color: #374151;
  }
}
body.light-mode .presale {
  border: 1px solid rgba(156, 163, 175, 0.45);
  background: #f5f5f8;
  & h3 {
    color: var(--lm-text-black-2);
  }
  & h4 {
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 18px;
    font-style: normal;
    font-weight: var(--font-normal);
    line-height: 23px;
    background: linear-gradient(136deg, #debf51 0%, #bd791b 101.3%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 0;
    margin: 0;
  }
  & h5 {
    color: var(--lm-text-black-2);
  }
  & p {
    color: var(--lm-text-grey-3);
  }
  & p span {
    color: var(--lm-text-black-2);
  }
  & .lines {
    background: #1f2a37;
    & .dots {
      background: #1f2a37;
    }
  }
  & .dots {
    background: var(--lm-text-black-2);
  }
  & .usdt-details h5 {
    color: var(--lm-text-grey-3);
  }
  & .usdt-details h6 {
    color: var(--lm-text-black-2);
  }
  & .usdtdata {
    background: var(--text-white);
    backdrop-filter: blur(150px);
  }
  & .usdtdata h3 {
    color: var(--lm-text-black-2);
  }
  & .usdtdata h6 {
    color: var(--lm-text-black-2);
  }
  & .usdtdata input {
    color: var(--lm-text-grey-2);
  }
  & .usdtdata input::-webkit-input-placeholder {
    color: rgba(156, 163, 175, 0.3);
  }
  & .usdtbtn {
    color: var(--lm-text-black-2);
  }
  & .aocbtn {
    color: var(--lm-text-black-2);
  }
  & .buytoken {
    border-radius: 12px;
    border: 2px solid #f5d255;
    background: rgba(245, 210, 85, 0.32);
    color: var(--lm-text-black-2);
  }
  & .buytoken:hover {
    background: rgba(245, 210, 85, 1);
  }
  & .claim {
    border-radius: 12px;
    border: 2px solid #f5d255;
    background: rgba(245, 210, 85, 0.32);
    color: var(--lm-text-black-2);
  }
  & .claim:hover {
    background: rgba(245, 210, 85, 1);
  }
}

.claim-button {
  /* border: 1px solid rgba(156, 163, 175, 0.45);
    background: #f5f5f8; */
  & .claim {
    font-family: "ProximaNovaFont", sans-serif;
    border-radius: 12px;
    border: 1px solid rgba(245, 210, 85, 0.2);
    background: rgba(245, 210, 85, 0.1);
    padding: 10px;
    width: 150px;
    font-size: 20px;
    font-weight: var(--font-medium);
    color: #fff;
    text-align: center;
    transition: 0.25s ease-in-out;
    text-decoration: none;
    cursor: pointer;
  }
  & .claim {
    border-radius: 12px;
    border: 2px solid #f5d255;
    background: rgba(245, 210, 85, 0.32);
    color: var(--text-white);
  }
  & .claim:hover {
    background: rgba(245, 210, 85, 1);
  }
}
body.light-mode {
  & .claim {
    color: var(--lm-text-black-2);
  }
  & .footer-logo .darkfooter-logo{
    display: none;
  }
}
/**profile**/
body.light-mode .profile {
  & li a {
    color: #636366;
  }
  & li a.active {
    border: 2px solid #f5d255;
    background: rgba(245, 210, 85, 0.32);
    color: var(--lm-text-black-2);
  }
}
body.light-mode .profile-view {
  &::before {
    content: "";
    position: absolute;
    background: #d1d1d6;
    width: 1px;
    left: 15px;
    transform: translateX(15px);
    /* height: 100%; */
  }
  & h1 {
    color: var(--lm-text-black-2);
  }
  & p {
    color: var(--lm-text-grey-3);
  }
  & label {
    color: var(--lm-text-grey-3);
  }
  & .form-control {
    border: 1px solid rgba(156, 163, 175, 0.45);
    background: #f5f5f8;
    color: var(--lm-text-black-2);
  }
  & .country-code {
    color: var(--lm-text-black-2) !important;
  }
}
body.light-mode .profile-edit {
  & h3 {
    color: var(--lm-text-grey-3);
  }
  & .profile-img {
    width: 135px;
    height: 135px;
    outline: 0px solid #313235;
    outline-offset: 0px;
    background: #e5e5ea;
    color: var(--lm-text-grey-3);
  }
}
body.light-mode .socail-share ul {
  & li a {
    border: 1px solid #d1d1d6;
    background: #fff;
    color: #4b5563;
  }
  & li a:hover {
    color: #debf51;
    transition: 0.25 ease-in-out;
    background: #374151;
  }
}
body.light-mode .lines {
  background: var(--lm-text-black-2);
  & .dots {
    background: var(--lm-text-black-2);
  }
}
/* .main-dashboard .header {
  border-bottom: 0;
} */
/* body.light-mode .main-dashboard .header {
  border-bottom: 0 !important;
} */
.main-dashboard .navbar-expand-lg .navbar-collapse {
  justify-content: end;
}

.navbar-toggler {
  background-color: #debf51 !important;
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 900px !important;
  }
}
@media (max-height: 400px) {
  .walletview {
    height: auto;
  }
}
/**darkmode and light themes**/

.customswitch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 18px;

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  & .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(217, 217, 217, 0.17);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  & .slider:before {
    position: absolute;
    content: "";
    height: 11px;
    width: 11px;
    left: 4px;
    bottom: 3.5px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  & input:checked + .slider {
    /* background-color: #9CA3AF; */
    background-color: rgba(217, 217, 217, 0.17);
  }
  & input:checked + .slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
  }
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
}
body.light-mode .customswitch {
  & .slider {
    background-color: #d1d1d6;
  }

  & .slider:before {
    background-color: white;
  }
  & input:checked + .slider {
    /* background-color: #9CA3AF; */
    background-color: var(--lm-text-grey-3);
  }
}

/**modal darkmode**/
.modal-content {
  background: none !important;
}
body.light-mode .modal-body {
  background: var(--text-white) !important;
}
body.light-mode .custom-modal-head {
  border: 1px solid var(--text-primary);
  background: linear-gradient(
    136deg,
    rgba(222, 191, 81, 0.05) 0%,
    rgba(189, 121, 27, 0.05) 101.3%
  );

  & h3 {
    color: var(--lm-text-black-2);
  }
  & p {
    font-family: var(--font-plus), sans-serif;
    color: var(--lm-text-grey-3);
    font-size: 16px;
    font-style: normal;
    font-weight: var(--font-normal);
    line-height: 27px;
    padding: 0;
    margin: 0;
  }
  & .close_btn {
    color: #0e0e2c;
  }
}
body.light-mode .custom-modal-body {
  & li {
    color: var(--lm-text-grey);
  }
  & h3 {
    color: var(--text-orange);
  }
  & .bible-modal-wrap {
    border: 1px solid var(--text-primary);
  }
}

.modal-backdrop {
  z-index: -1;
}

/* @media(max-width:1280px){
    #partners .owl-carousel .owl-item {
      width: 270px !important;
    }
  }
  @media(max-width:1199px){
    #partners .owl-carousel .owl-item {
      width: auto !important;
    }
  }*/
.condition-modal {
  & .modal-content {
    background: none !important;
    border-radius: 20px;
    padding: 0px;
  }
  & .custom-head {
    padding: 15px 21px;
    border-radius: 20px;
    border: 1px solid var(--text-primary);
    background: linear-gradient(
      136deg,
      rgba(222, 191, 81, 0.05) 0%,
      rgba(189, 121, 27, 0.05) 101.3%
    );
    -webkit-backdrop-filter: blur(150px);
    backdrop-filter: blur(150px);
    position: relative;
    z-index: 2;
  }
  & h3 {
    font-size: 28px;
    line-height: 40px;
    font-weight: var(--font-medium);
    color: var(--text-white);
    margin: 0;
    padding: 0;
  }
  & .modal-body {
    padding: 20px 20px 30px 20px !important;
    border-radius: 36px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: #130c04 !important;
  }
  & ul {
    padding: 0px 26px 0px;
    margin: 0;
    list-style: none;
  }
  & li {
    font-family: var(--font-plus), sans-serif;
    color: var(--text-grey);
    font-size: 16px;
    font-weight: var(--font-normal);
    line-height: 26px;
    padding: 0 10px 25px 10px;
    margin: 0 0 0 30px;
    list-style-type: decimal;
  }
  & p {
    font-family: var(--font-plus), sans-serif;
    color: var(--text-grey);
    font-size: 16px;
    font-weight: var(--font-normal);
    line-height: 26px;
    padding: 0 10px 20px;
    margin: 0;
  }
  & li:last-child {
    padding: 0 10px 0px 10px;
  }
  & .float-right {
    float: right;
  }
  & .close_btn {
    width: 36px;
    height: 36px;
    padding: 6px;
    border-radius: 8px;
    border: 1px solid var(--text-primary);
    background: var(----close-btn-bg);
    color: var(--text-white);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 3;
    transition: 0.25s ease-in-out;
    opacity: 1;
    cursor: pointer;
  }
  & .close_btn:hover {
    background: linear-gradient(136deg, #debf51 0%, #bd791b 101.3%) !important;
    color: var(--text-white);
    transition: 0.25s ease-in-out;
  }
}
body.light-mode .condition-modal {
  & .modal-body {
    border-radius: 20px;
    background: var(--lm-text-black-2);
  }
  & h3 {
    color: var(--lm-text-black-2);
  }
  & .close_btn {
    color: #0e0e2c;
  }
  & li {
    color: var(--lm-text-grey);
  }
  & p {
    color: var(--lm-text-grey);
  }
}
.condition-modal .drag-body {
  height: calc(100vh - 240px);
  overflow-y: auto;
}
@media (max-width: 992px) {
  .condition-modal {
    & h3 {
      font-size: 20px;
    }
    & .custom-head {
      padding: 7px 21px;
    }
    & .modal-body {
      padding: 20px 10px 30px 20px !important;
    }
    & li {
      padding: 0 0px 25px 10px;
      margin: 0 0 0 10px;
    }
  }
}

/* body .header-sticky {
  background: #130c04;
}
body.light-mode .header-sticky {
  background: #f2f2f7 !important;
} */
.standard {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 9 !important;
  /* background: #130c04; */
  transition: 0.5s ease-in-out;
}
/* body.light-mode .standard {
  background: #fff;
} */
body.light-mode .btn.translation {
  border: 1px solid var(--lm-text-grey-2);
  font-size: 16px;
  padding: 5px 10px;
  color: var(--lm-text-black-2);
  transition: 0.25s ease-in-out;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background: #130c04;
  height: auto;
}

body.light-mode .sticky {
  background: #f2f2f7;
}
/* .standard {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 999 !important;
  background: #130c04;
} */
/* body.light-mode .standard {
  background: #f2f2f7 !important;
} */
/**what app pulse effect*/
.btn-whatsapp-pulse {
  background: #25d366;
  color: #fff;
  position: fixed;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  padding: 25px;
  text-decoration: none;
  border-radius: 50%;
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
  }
  80% {
    box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
  }
}

.btn-whatsapp-pulse-border {
  bottom: 80px;
  right: 20px;
  animation-play-state: paused;
}

.btn-whatsapp-pulse-border::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  padding: 20px;
  border: 5px solid #25d366;
  opacity: 0.75;
  animation-name: pulse-border;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

@keyframes pulse-border {
  0% {
    padding: 25px;
    opacity: 0.6;
  }
  75% {
    padding: 50px;
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
/** our team section**/
.ourteam-section {
  padding: 60px 0;
  & h1 {
    font-size: 24px;
    line-height: 36px;
    font-weight: var(--font-semibold);
    text-align: center;
    color: var(--text-white);
    font-variant-numeric: lining-nums;
    margin: 0 0 40px;
    padding: 0;
  }
  & h2 {
    font-size: 22px;
    line-height: 36px;
    font-family: var(--font-plus), sans-serif;
    font-variant-numeric: lining-nums;
    font-weight: var(--font-semibold);
    color: var(--text-white);
    margin: 0 0 20px;
    padding: 0;
  }
  & .ourteam-member {
    padding: 15px;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.04);
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    transition: 0.25s ease-in-out;
    height: 100%;
    position: relative;
  }
  & .ourteam-member img {
    width: 100%;
    height: 225px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 15px;
    object-fit: cover;
    object-position: top;
  }
  & h3 {
    font-size: 20px;
    line-height: 30px;
    font-weight: var(--font-semibold);
    color: var(--text-white);
    font-family: var(--font-plus), sans-serif;
    padding: 0 0 10px;
    margin: 0;
  }
  & h4 {
    font-size: 16px;
    line-height: 26px;
    font-weight: var(--font-medium);
    color: var(--text-white);
    padding: 0 0 5px;
    margin: 0;
  }
  & h5,
  h6 {
    font-size: 16px;
    line-height: 26px;
    font-weight: var(--font-medium);
    color: var(--text-white);
    padding: 0 0 5px;
    margin: 0;
  }
  & h6 {
    font-weight: var(--font-semibold);
    font-variant-numeric: lining-nums;
    color: var(--text-white);
  }
  & ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 15px;
    row-gap: 0;
  }

  & li a {
    border: 1px solid var(--text-primary);
    padding: 5px 5px;
    border-radius: 5px;
    color: var(--lm-text-grey-2);
    transition: ease all 0.25s;
    display: block;
  }
  & li a:hover {
    color: var(--text-primary);
    transition: ease all 0.25s;
  }
}

body.light-mode .ourteam-section {
  padding: 60px 0;

  & h1,
  h2 {
    color: var(--lm-text-black-2);
  }
  & .ourteam-member {
    border: 1px solid #debf51;
    background: linear-gradient(
      136deg,
      rgba(222, 191, 81, 0.05) 0%,
      rgba(189, 121, 27, 0.05) 101.3%
    );
  }
  &.ourteam-member:hover {
    border: 1px solid var(--text-primary);
  }
  & h3 {
    color: var(--lm-text-black-2);
  }
  & h4 {
    color: var(--lm-text-grey);
  }
  & h5,
  h6 {
    color: var(--lm-text-grey);
  }
  & h6 {
    color: var(--lm-text-black-2);
  }
}

.selectlanguage {
  padding: 18px 20px;
  border-radius: 8px;
  border: 1px solid var(--text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-white);
  font-size: 24px;
  font-weight: 700;
  font-family: var(--font-plus), sans-serif;
  transition: 0.25s ease-in-out;
  cursor: pointer;
  width: 48%;
  font-family: "ProximaNovaFont" !important;
  background: transparent;
}
.selectlanguage img {
  margin-right: 22px;
}
.selectlanguage:focus {
  background: linear-gradient(136deg, #debf51 0%, #bd791b 101.3%);
  transition: 0.25s ease-in-out;
  border: 1px solid var(--text-primary);
  color: var(--text-white);
}
.selectlanguage:hover {
  background: linear-gradient(136deg, #debf51 0%, #bd791b 101.3%);
  transition: 0.25s ease-in-out;
  border: 1px solid var(--text-primary);
  color: var(--text-white);
}
.selectlanguage:hover span,
.selectlanguage:focus span {
  color: var(--text-white);
}
.selectlanguage span {
  color: var(--lm-text-grey-4);
}
body.light-mode .selectlanguage {
  color: var(--lm-text-black-2);
}
body.light-mode .selectlanguage:hover span,
body.light-mode .selectlanguage:focus span {
  color: var(--lm-text-black-2);
}

p a {
  word-break: break-all;
}
.ourteam-section {
  & li {
    color: var(--text-secondary);
  }
}
body.light-mode .ourteam-section {
  & li {
    color: var(--lm-text-grey-3);
  }
}
.admin-main {
  min-height: 100vh;
  /* min-height: calc(100vh - 102px);
  display: flex;
  align-items: center;
  justify-content: center; */
  padding: 60px 0 90px;
}
.alpha {
  font-size: 20px;
  font-family: var(--font-plus), sans-serif;
  font-weight: var(--font-normal);
  border-radius: 38px;
  background: linear-gradient(136deg, rgba(222, 191, 81, 0.15) 0%, rgba(189, 121, 27, 0.15) 101.3%);
  color: #374151;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  padding: 4px 4px 4px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: -moz-fit-content;
  width: fit-content;
  transition: 0.25s ease-in-out;
  cursor: pointer;
  &:hover {
    background: linear-gradient(136deg, rgba(222, 191, 81, 1) 0%, rgba(189, 121, 27, 1) 101.3%);
    color: #fff;
}
}
.alpha:hover .arrow {
transform: rotate(360deg);
transition: 0.25s ease-in-out;
transition-property: transform 0.25s;
background: var(--text-white);
}
.arrow {
width: 54px;
height: 54px;
border-radius: 73px;
border: 1px solid #debf51;
background: linear-gradient(136deg, rgba(222, 191, 81, 0.42) 0%, rgba(189, 121, 27, 0.42) 101.3%);
display: flex;
align-items: center;
justify-content: center;
}

.light-mode-text {
  color: #252525;
}
.globaltokenomics-wrap {
  & table {
    border: 1px solid #DEBF51;
    & thead tr {
      & th {
        /* background: linear-gradient(136deg, rgba(222, 191, 81, 0.07) 0%, rgba(189, 121, 27, 0.07) 101.3%), #FFF; */
        color: var(--light-mode-text);
        color: var(--light-mode-text);
        border-bottom: 1px solid #c6a73a;
        border-right: 1px solid #c6a73a;
        background: var(--Linear, linear-gradient(136deg, #DEBF51 0%, #DEBF51 101.3%));
        & span {
          color: var(--lm-text-grey);
        }
      }
      & td {
        background-color: #ffffff;
        color: var(--light-mode-text);
        border-right: 1px solid #DEBF51;
        & p {
          color: #4B5563;
        }
      }
    }
    & tbody tr {
      & th {
        border-right: 1px solid #DEBF51;
      }
      & td {
        border-right: 1px solid #DEBF51;
      }
    }
    & tfoot { 
      background: linear-gradient(136deg, rgba(222, 191, 81, 0.07) 0%, rgba(189, 121, 27, 0.07) 101.3%), #FFF;
      color: var(--light-mode-text);
      & tr {
        & td {
          border-top: 1px solid #DEBF51;
          border-right: 1px solid #DEBF51;
          background: linear-gradient(136deg, rgba(222, 191, 81, 0.07) 0%, rgba(189, 121, 27, 0.07) 101.3%), #FFF;
        }
      }
    }
  }
}
.supply-card {
  border: 1.5px solid #DEBF51;
}
.statistical-detail-wrap {
  & .statistical-detail {
    border: 1.5px solid #DEBF51;
  }
}

/* Dark & Light theme color Start */
.distribution-card .distribution-content {
      & h1 {
          color: var(--light-mode-text);
      }
      & p {
          color: var(--lm-text-grey);
      }
  }
  .distribution-para {
    & p {
        color: var(--lm-text-grey);
        & a {
            color: var(--lm-text-grey);
            border-bottom: 1px solid var(--lm-text-grey);
        }
    }
}

.wallet-card p {
  color: var(--lm-text-grey);
}
.wallet-main .distribution-card p {
  color: var(--lm-text-grey);
}
.wallet-main .distribution-card button {
  color: var(--switch-text-color);
}
.wallet-main .distribution-card button:hover {
  color: var(--lm-text-grey);
 }
.dark-mode .main-dashboard .nav-item .nav-link {
  color: var(--switch-text-color);
  &:hover {
    color: var(--text-primary);
  }
  &.active {
    color: var(--switch-text-color);
    &:hover {
      color: var(--text-primary);
    }
  }
}
.dark-mode .wallet-btn button, .dark-mode .alpha, .dark-mode .wallet-main .distribution-card p, .dark-mode .wallet-main .distribution-card button:hover, .dark-mode .distribution-card .distribution-content h1 {
    color: var(--switch-text-color);
    }
    .dark-mode .distribution-para {
      & p {
          color: var(--text-secondary);
          & a {
              color: var(--text-secondary);
              border-bottom: 1px solid var(--text-secondary);
          }
      }
  }
  .dark-mode .distribution-card .distribution-content p, .dark-mode .wallet-card p {
    color: var(--text-secondary);
  }
.dark-mode .navbar.scrolled {
  background-color: #130c04 !important;
}
.dark-mode .btn.translation svg path {
  stroke: var(--switch-text-color);
} 
.category-list {
    & li {
        border: 2px solid #F5D255;
        color: var(--lm-text-grey);
        background: #FFF;
        &.active {
            color: var(--lm-text-black-2);
        }
        &:hover{
            color: var(--lm-text-black-2);
        }
    }
}
.dark-mode .category-list {
  & li {
      color: var(--switch-text-color);
      background: rgba(245, 210, 85, 0.32);
      &.active {
          color: var(--switch-text-color);
      }
      &:hover{
          color: var(--switch-text-color);
      }
  }
}
.globaltoken-title {
  & h1,h2 {
      color: var(--light-mode-text);
  }
  & p {
      color: var(--lm-text-grey);
  }
}
.supply-card { 
  & h5 {
    color: #4B5563;
  }
  & p {
    color: var(--lm-text-grey);
  }
}

.statistical-detail-wrap {
  & h4 {
      color: var(--light-mode-text);
  }
  & .statistical-detail {
      & h5 {
          color: var(--lm-text-grey);
      }
      & h6 { 
          color: var(--light-mode-text);
      }
     
  }
}
.main-dashboard.scrolled {
  background-color: #ebebf0;
}
.category-list a {
  color: #1F2A37;
}
.dark-mode {
    & .globaltoken-title {
      & h1,h2 {
        color: var(--switch-text-color);
      }
      & p {
        color: var(--switch-text-color);
      }
  }
  .supply-card { 
    border: 1.5px solid rgba(255, 255, 255, 0.4);
    & h5 {
      color: var(--switch-text-color);
    }
    & p {
      color: var(--switch-text-color);
    }
  }
  .statistical-detail-wrap {
    & h4 {
        color: var(--switch-text-color);
    }
    & .statistical-detail {
      border: 1.5px solid rgba(255, 255, 255, 0.4);
        & h5 {
            color: var(--text-grey);
        }
        & h6 { 
            color: var(--switch-text-color);
        }
       
    }
  }
  .globaltokenomics-wrap {
      & table {
        border: 1px solid rgba(255, 255, 255, 0.4);
        & thead tr {
          & th {
              background: linear-gradient(136deg, rgba(222, 191, 81, 0.07) 0%, rgba(189, 121, 27, 0.07) 101.3%);
              color: var(--text-white);
              border-right: 1px solid rgba(255, 255, 255, 0.4);
              & span {
                color: var(--text-white);
              }
              
          }
          & td {
            background-color: transparent;
            color: var(--text-white);
            border-right: 1px solid rgba(255, 255, 255, 0.4);
            & p {
              color: var(--text-white);
            }
          }
        }
        & tbody tr {
          & th {
            background-color: transparent;
            color: var(--text-white);
            border-right: 1px solid rgba(255, 255, 255, 0.4);
          }
          & td {
            background-color: transparent;
            color: var(--text-white);
            border-right: 1px solid rgba(255, 255, 255, 0.4);
          }
        }
        & tfoot { 
          background: linear-gradient(136deg, rgba(222, 191, 81, 0.07) 0%, rgba(189, 121, 27, 0.07) 101.3%);
          color: var(--text-white);
          & tr {
            & td {
              color: var(--text-white);
              border-top: 1px solid rgba(255, 255, 255, 0.4);
              border-right: 1px solid rgba(255, 255, 255, 0.4);
              background: linear-gradient(136deg, rgba(222, 191, 81, 0.07) 0%, rgba(189, 121, 27, 0.07) 101.3%);
            }
          }
        }
      }
  }
  .main-dashboard.scrolled {
    background-color: #130c04;
  }
  .category-list a {
    color: #ffffff;
  }
  & .footer-logo .lightfooter-logo{
    display: none;
  }
}
/* Dark & Light theme color End */

.dashboardpage {
  position: relative;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  /* &::after {
    content: "";
    position: fixed;
    bottom: 0;
    left: 0;
    width: 420px;
    height: 370px;
    background: url("assets/images/wallet-left-circle.svg")
      no-repeat;
    z-index: -1;
  } */
  &::before {
    content: "";
    position: absolute;
    left: 100px;
    top: 125px;
    width: 30px;
    height: 30px;
    background: url("assets/images/star-left-1.svg")
      no-repeat;
      animation: blink 1.5s ease-in-out infinite;
      background-size: cover;
  }
}

body::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 150px;
  height: 100%;
  background: url("assets/images/wallet-right-circle.svg")
    no-repeat;
  z-index: -1;
}
body::after {
  content: "";
  position: absolute;
  top: 450px;
  left: 0;
  width: 420px;
  height: 740px;
  background: url("assets/images/wallet-left-circle.svg")
    no-repeat;

    background-position: center;
    background-size: contain;
  z-index: -1;
}
.wallet-main {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 60px;
    top: 50px;
    width: 60px;
    height: 60px;
    background: url("assets/images/star-left-1.svg")
      no-repeat;
      animation: blink 1.5s ease-in-out infinite;
      background-size: cover;
  }
  .banner-title {
    &::before {
      content: "";
      position: absolute;
      right: 10px;
      top: 85px;
      width: 60px;
      height: 60px;
      background: url("assets/images/star-left-1.svg")
        no-repeat;
        animation: blink 1.5s ease-in-out infinite;
        background-size: cover;
    }
  }
}
.distribution-card::before {
      background: url(./assets/images/distibution-bg.png) no-repeat;
}
.wallet-main .distribution-card::before {
  background: url(./assets/images/distibution-bg2.png) no-repeat;
}
.globaltokenomics-wrap .table-responsive {
  padding-bottom: 4px;
}
.globaltokenomics-wrap .table-responsive::-webkit-scrollbar {
  height: 8px;
}

.globaltokenomics-wrap .table-responsive::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent; 
  border-radius: 10px;
}

.globaltokenomics-wrap .table-responsive::-webkit-scrollbar-thumb {
  background: #f8d75e; 
  border-radius: 10px;
}

.globaltokenomics-wrap .table-responsive::-webkit-scrollbar-thumb:hover {
  background: #f5d255; 
}
.btn-group.translation .dropdown-menu .dropdown-item {
  line-height: 35px!important;
}
.btn-group.translation .dropdown-menu .dropdown-item:active {
  background-color: #debf51;
}



@media (min-width: 1500px) {
  .main-dashboard nav {
    max-width: 1600px;
    margin: 0 auto;
    padding: 24px 65px 0;
  }
  .main-dashboard .nav-item .nav-link {
    font-size: 15px;
  }
}


@media (min-width: 1440px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1140px !important;
  }
  .main-dashboard .nav-item .nav-link {
    font-size: 14px;
  }
  .main-dashboard .navbar-nav {
    gap: 18px;
  }
}

/* App section */
.app-section {
  padding-bottom: 104px;
}
.app-section h2 {
  color: var(--text-white);
  font-size: 52px;
  font-weight: var(--font-semibold);
  line-height: normal;
}
.app-section p {
  color: var(--text-secondary);
  font-family: "Plus Jakarta Sans";
  font-size: 24.35px;
  font-weight: 400;
  line-height: 35.794px;
  margin-bottom: 50px;
}
.app-btns {
  display: flex;
  gap: 38px;
}
.app-card {
  display: flex;
  justify-content: center;
  padding-top: 45px;
  border-radius: 20px;
  border: 1px solid #DEBF51;
  background: linear-gradient(136deg, rgba(222, 191, 81, 0.05) 0%, rgba(189, 121, 27, 0.05) 101.3%);
  backdrop-filter: blur(150px);
}

@media (max-width: 1420px) {
  .main-dashboard .nav-item .nav-link {
    font-size: 12px;
  }
  .main-dashboard .navbar-nav {
    gap: 24px;
  }
}
@media (max-width: 1340px) {
  .navbar-brand img {
    width: 170px;
  }
  .main-dashboard .navbar-nav {
    gap: 14px;
  }
  .main-dashboard nav {
    padding: 20px 30px 0 30px;
  }
  .main-dashboard nav .header {
    padding: 0 0 20px 0;
  }
  .main-dashboard .nav-item .nav-link {
    font-size: 12px;
  }
  
}
@media (max-width: 1280px) {
  .app-btn {
    padding: 8px 10px;
    font-size: 16px;
  }
  /* .main-dashboard header {
    padding: 0px 20px 0px !important;
  } */
  /* .main-dashboard .header {
    padding: 15px 40px 15px 40px;
    border-bottom: 0px solid rgba(255, 255, 255, 0.15);
  } */
  .ourteam-section {
    & h1 {
      font-size: 24px;
      line-height: 35px;
    }
  }
  .main-dashboard nav {
    padding: 20px 20px 0 20px;
  }
  .switches-container {
    width: 70px;
    height: 35px;
  }
  .switches-container  .switch div {
    font-size: 18px;
  }
  .switches-container label {
    font-size: 20px;
  }
  .switches-container .btn.translation img {
    width: 24px;
  }
  .switches-container .translation .dropdown-menu img {
    width: 24px;
  }
  .alpha {
    font-size: 18px;
    gap: 15px;
  }
  .banner-title h1 {
    font-size: 42px;
    padding: 0 0 20px;
  }
  .arrow {
    width: 44px;
    height: 44px;
  }
  /* .banner-title p {
    font-size: 18px;
    margin: 0 auto 35px;
  } */
  .wallet-main::before {
    left: 50px;
  }
  .navbar-expand-xl .navbar-nav {
    gap: 14px;
  }
  .main-dashboard .nav-item .nav-link {
    font-size: 11px;
  }
}
@media (max-width: 1199px) {
  .common-heading {
    & h3 {
      font-size: 42px;
    }
    & p {
      font-size: 22px;
      line-height: 32px;
    }
  }
  .main-dashboard .navbar-nav {
    flex-direction: column;
    align-items: start;
  }
  .main-dashboard .navbar-collapse.show {
    display: flex;
    margin: 30px 0 0;
    gap: 10px;
    flex-direction: column;
    align-items: start;
}
.navbar-toggler:focus {
  /* box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width); */
  --bs-navbar-toggler-focus-width: 2px;
}
.app-section h2 {
  font-size: 46px;
}
}
@media (max-width: 1024px) {
  .custom-modal-head {
    padding: 20px 20px 20px 20px;

    & h3 {
      font-size: 20px;
      line-height: 30px;
    }
    & p {
      font-size: 15px;
      line-height: 26px;
    }
  }
  .custom-modal-body {
    padding: 20px 20px 0px;
    & li {
      font-size: 15px;
      line-height: 26px;
    }
  }
  .modal-body {
    padding: 10px 10px 20px !important;
  }
  /* .main-dashboard .nav-item .nav-link {
    padding: 5px 15px !important;
  } */
}
@media (max-width: 991px) {
  /* .main-dashboard .header {
    padding: 0px 0px 0px;
    border-bottom: 0px solid rgba(255, 255, 255, 0.15);
  } */
  .selectlanguage {
    width: 100%;
    font-size: 20px;
  }
  .ourteam-section {
    & h1 {
      font-size: 20px;
      line-height: 30px;
    }
    & h2 {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 0px;
    }
  }
  /* .main-dashboard .navbar-collapse.show {
    display: flex;
    margin: 30px 0 0;
    gap: 10px;
    flex-direction: column;
    align-items: start;
  } */
  .main-dashboard .navbar-nav {
    /* flex-direction: column;
    width: 100%;
    align-items: start; */
    gap: 15px;
  }
  /* .main-dashboard .header {
    padding: 0px 0px 23px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.15);
  } */
  /* body.light-mode .main-dashboard .header {
    border-bottom: 0px solid rgba(156, 163, 175, 0.2) !important;
  } */
  /* .main-dashboard {
    padding: 20px 20px 0px;
    z-index: 999;
    position: relative;
  } */

  .main-dashboard {
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }
  .saledetail {
    flex-wrap: wrap;
  }
  .main-dashboard nav {
    padding: 15px 35px 0 35px;
}
.main-dashboard nav .header {
  padding: 0 0 15px 0;
}
.banner-title h1 {
  font-size: 32px;
  padding: 0 0 15px;
}
.alpha {
  font-size: 16px;
  gap: 12px;
  padding:4px 4px 4px 20px;
}
.arrow {
  width: 36px;
  height: 36px;
  & svg {
    width: 32px;
    height: 32px;
  }
}
.banner-title p {
  font-size: 18px;
  line-height: 28px;
  width: 70%;
}
.dashboardpage::before {
  display: none;
  /* left: 45px;
  width: 45px;
  height: 45px; */
}
.wallet-main::before {
  top: 0px;
}
.wallet-main .banner-title::before, .wallet-main::before {
  width: 45px;
  height: 45px;
}
.navbar-toggler-icon {
  width: 26px !important;
  height: 26px !important;
}
/* .admin-main {
  min-height: calc(100vh - 80px);
} */
}

@media (max-width: 767px) {
  .wallet-header {
    padding: 30px 20px 30px;
    flex-wrap: wrap;
  }
  .wrap {
    height: 100%;
  }
  .main-dashboard .navbar-expand-lg .navbar-collapse {
    justify-content: start;
  }
  .main-dashboard nav {
    padding: 15px 25px 0 25px;
}
.navbar-toggler {
  padding: 4px 8px !important;
}
.alpha {
  font-size: 15px;
}
.arrow svg {
  width: 28px;
  height: 28px;
}
.arrow {
  width: 32px;
  height: 32px;
}
.banner-title {
  padding: 20px 0px 0px;
}
.banner-title h1 {
  font-size: 28px;
}
.banner-title p {
  font-size: 16px;
  line-height: 25px;
  width: 90%;
}
/* .dashboardpage::before {
  left: 35px;
  width: 35px;
  height: 35px;
  top: 30px;
} */
.wallet-main::before {
  width: 35px;
  height: 35px;
}
.dashboardpage::after {
  left: -60px;
}
.wallet-main .banner-title::before {
  right: 0px;
  width: 35px;
  height: 35px;
}
.main-dashboard .navbar-nav {
  gap: 10px;
}
.switches-container .btn.translation img {
  width: 20px;
}
.switches-container {
  width: 66px;
}
.switches-container .switch div {
  font-size: 16px;
}
.switches-container label {
  font-size: 18px;
}
.btn-group.translation .dropdown-menu .dropdown-item {
  line-height: 35px!important;
}
.navbar-toggler-icon {
  width: 24px !important;
  height: 24px !important;
}
.banner-title {
  padding: 25px 0px 0px;
}
.app-section h2 {
  font-size: 40px;
}
.app-section p {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 40px;
}
.app-btns img {
  width: 180px;
  height: auto;
}
.app-section {
  padding-bottom: 80px;
}
}
@media (max-width: 575px) {
  .common-heading {
    & h3 {
      font-size: 20px;
    }
    & p {
      font-size: 15px;
      line-height: 25px;
    }
  }
  .banner-cicrle::before {
    width: 65% !important;
  }
 
  .apps-btn,
  .read-btn {
    font-size: 18px;
    padding: 15px 15px;
  }
  .main-dashboard {
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }
  .bible-section {
    margin-bottom: 10px;
  }
  .dashboardpage::after {
    display: none;
  }
  .navbar-brand img {
    width: 165px;
}
.banner-title h1 {
  font-size: 24px;
}
.main-dashboard nav {
  padding: 15px 15px 0 15px;
}
/* .banner-title p {
  font-size: 16px;
  line-height: 24px;
  margin: 0px auto 25px;
} */
body::after {
  width: 300px;
  height: 500px;
  background-position: center;
  background-size: contain;
}
body::before{
  height: 500px;
  background-position: center;
  background-size: contain;
}
.wallet-main .banner-title::before {
 top: 45px;
 width: 25px;
 height: 25px;
}
.wallet-main::before {
  left: 20px;
  width: 25px;
  height: 25px;
}
.app-section p {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 30px;
}
.app-section h2 {
  font-size: 34px;
}
.app-btns {
  gap: 25px;
}
.app-btns img {
  width: 160px;
  height: auto;
}
.app-section {
  padding-bottom: 60px;
}
  
}
@media (max-width: 360px) {
  /* .navbar-brand img {
    width: 180px;
  } */
  .main-dashboard .user-wallet {
    padding: 14px 10px;
  }
  .app-section h2 {
    font-size: 28px;
  }
  .app-btns {
    gap: 20px;
  }
  .app-btns img {
    width: 140px;
    height: auto;
  }
  .app-section {
    padding-bottom: 40px;
  }
}

/* table > thead > tr > th:first-child {
  border-radius: 0px !important;
}
table > thead > tr > th {
  padding: 0px !important;
  color: var(--lm-text-black-2) !important;
} */
.owner-details {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 25px;
  & h5 {
    color: var(--text-orange);
    text-align: left;
    font-family: var(--font-plus), sans-serif;
    font-size: 18px;
    font-weight: var(--font-normal);
    line-height: 30px;
    padding: 0;
    margin: 0;
    width: 30%;
  }
  & h6 {
    color: var(--lm-text-black-2);
    text-align: left;
    font-family: var(--font-plus), sans-serif;
    font-size: 16px;
    font-weight: var(--font-normal);
    line-height: 26px;
    padding: 0;
    margin: 0;
    width: 70%;
  }
  & mb-0 {
    margin-bottom: 0 !important;
  }
}
body.dark-mode .owner-details {
  & h5 {
    color: var(--text-white);
  }
  & h6 {
    color: var(--lm-text-grey-2);
  }
}
@media (max-width: 991px) {
  .owner-details {
    flex-wrap: wrap;
    row-gap: 10px;
    margin-bottom: 15px;
    & h5 {
      width: 100%;
    }
    & h6 {
      width: 100%;
    }
  }
}
/**transaction details**/
.custom-modal-head.afternone::after {
  display: none !important;
}
.custom-modal-head.afternone h3 {
  margin: 0;
}
.transaction-wrap {
  & .stepcount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
    & h3 {
      font-size: 18px;
      line-height: 26px;
      font-weight: var(--font-medium);
      color: var(--lm-placeholder);
      padding: 0;
      margin: 0;
    }
    & .success {
      color: #4bb543;
      font-size: 24px;
    }
    & .failure {
      color: #ff0000;
      font-size: 24px;
    }
    & h3.inprogress {
      color: var(--lm-text-black-2);
    }
  }
  & .loader {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #bd791b;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 2s linear infinite;
  }
  & .loader {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #bd791b;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 2s linear infinite;
  }
}
.modal-header {
  border-radius: 20px !important;
  border-bottom-color: var(--text-primary) !important;
}
body.dark-mode {
  .transaction-wrap {
    & h3 {
      color: #909090;
    }
    & h3.inprogress {
      color: var(--text-white);
    }
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
w3m-button {
  margin: 0 auto !important;
}

.dark-mode-text {
  color: var(--text-white) !important;
}
@media (max-height: 500px) {
  .dashboardpage::after {
    height: 250px;
  }
}

@media (max-height: 400px) {
  .dashboardpage::after {
    height: 200px;
  }
}
.light-mode-text {
  color: #252525;
}

.footer-fixed {
    position: fixed;
    right: 10px;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    z-index: 1000;
    
}


.active-link {
  background-color: #fdde82; /* Light gray background for active link */
  color: #1f2937; /* Darker text color */
}